<template>
  <div>
    <div class="calendar-section">
      <div class="calendar-header">
        <div class="calendar-date">
          <span> </span>
          <a
            href="javascript:void(0)"
            class="material-icons arrow"
            @click.prevent="previousMonth()"
            >arrow_back_ios</a
          >
          <div class="date">
            <span class="material-icons calendar-icon">calendar_month</span>
            <!-- <h1>Today : {{ date.format("mmmm d, yyyy ddd") }}</h1> -->
            <h1>
              {{
                getMonthData(calendarNepMonth)
                  ? getMonthData(calendarNepMonth).eng_month
                  : ""
              }}, {{ calendarEngYear }} ({{ calendarNepYear }}
              {{
                getMonthData(calendarNepMonth)
                  ? getMonthData(calendarNepMonth).nep_month
                  : ""
              }})
            </h1>
          </div>

          <a
            href="javascript:void(0)"
            class="material-icons arrow"
            @click.prevent="nextMonth()"
          >
            arrow_forward_ios
          </a>
        </div>
        <!-- <h1>Today : {{ date.format("mmmm d, yyyy ddd") }}</h1> -->

        <!-- <div class="identification" v-for="(event,key) in calendarEventWithCount" :key="key"> -->
        <div
          class="identification"
          v-for="(ec, key) in calendarEventWithCount"
          :key="key"
        >
          <div class="production">
            <div
              class="production-color"
              :style="{ 'background-color': ec.color }"
            ></div>
            <h1>{{ key }} ({{ ec.total_count }})</h1>
          </div>

          <!-- <div class="production">
            <div
              class="production-color"
              style="background-color: #8b85ee"
            ></div>
            <h1>Growth</h1>
          </div> -->
          <!-- <div class="production">
            <div
              class="production-color"
              style="background-color: #8b85ee"
            ></div>
            <h1>Growth</h1>
          </div>

          <div class="production">
            <div
              class="production-color"
              style="background-color: #ffc4d0"
            ></div>
            <h1>Market</h1>
          </div> -->
        </div>

        <div class="add-button">
          <!-- <v-row class="add-button" align="center">
                <v-btn class="calendar-button" color="primary">
                  <span class="material-icons">add</span> Add New
                </v-btn>
              </v-row> 
          -->
        </div>
      </div>

      <div class="calendar-day">
        <div class="days">
          <h1 v-for="(header, index) in headers" :key="index">
            {{ header.name.capitalizeFirstLetter() }}
            <p class="header_nepali">({{ header.nep_day }})</p>
          </h1>
        </div>
      </div>

      <div
        class="v-sheet theme--light"
        style="height: 800px"
        v-if="schoolEvents.length > 0"
      >
        <div
          class="v-calendar-monthly v-calendar-weekly v-calendar theme--light v-calendar-events"
        >
          <div
            class="v-calendar-weekly__week"
            v-for="(value, index) in schoolEvents"
            :key="index"
          >
            <div
              v-for="(v, i) in value"
              :key="i"
              :style="
                v.date_iso == todayNepaliDate
                  ? 'border-left: 0px solid #f7f7f7;background:#2e7d32; color:#fff'
                  : 'border-left: 0px solid #f7f7f7;'
              "
              :class="
                v.disable_flag == 1
                  ? 'v-calendar-weekly__day v-future v-outside calendar table'
                  : 'v-calendar-weekly__day v-future'
              "
              @click="
                v.disable_flag != 1 && $auth.can('event-create')
                  ? (eventDialog = true)
                  : '',
                  (form.day_id = v.id),
                  (form.event_date = v.date_iso),
                  count++,
                  checkForPersonalInfo(v)
              "
            >
              <div class="v-calendar-weekly__day-label" style="color: #fff">
                <button
                  type="button"
                  class="v-btn v-btn--fab v-btn--has-bg v-btn--round theme--light v-size--small transparent"
                >
                  <!-- <span
                    class="v-btn__content nepali_date"
                    :style="v.is_holiday ? 'color:red' : ''"
                    >{{ v.day_bs }}
                  </span> -->
                  <span
                    class="v-btn__content nepali_date"
                    :style="
                      v.date_iso == todayNepaliDate
                        ? 'border-left: 0px solid #f7f7f7;background:#2e7d32; color:#fff'
                        : v.is_holiday
                        ? 'color:red'
                        : ''
                    "
                    >{{ v.day_bs }}</span
                  >
                </button>

                <!-- <p v-if="v.calendar_event != '--'">{{ v.calendar_event }}</p> -->
              </div>

              <div
                :data-date="v.date_iso"
                class="v-event v-event-start v-event-end white--text"
                :style="{
                  'background-color': e.event_type.color_code,
                  'border-color': e.event_type.color_code,
                  width: `${95 * e.actual_event_days.length}%`,
                  height: '18px',
                  'margin-bottom': '2px',
                  'margin-left': '5px',
                  color: '#fff',
                  'margin-top': `${
                    e.number_prev_events * 18 + (e.number_prev_events ? 3 : 0)
                  }px`,
                  'border-radius': '3px',
                  opacity: '0.8',
                }"
                v-for="(e, j) in v.events"
                :key="j"
              >
                <!-- <v-tooltip bottom> -->
                <!-- <template v-slot:activator="{ on, attrs }"> -->
                <div class="pl-1" @click="setData(e, v.is_holiday)">
                  <span
                    class="v-event-summary"
                    style="
                      text-align: left;
                      line-height: 0.8;
                      display: inline-block;
                    "
                  >
                    <strong>
                      {{ e.event_type.title }}
                      <span>{{
                        e.description ? ` >> ${e.description}` : ""
                      }}</span>
                    </strong>
                  </span>
                </div>
                <!-- </template> -->
                <!-- <span></span> -->
                <!-- </v-tooltip> -->
              </div>

              <!-- <span
                class="v-btn__content english_date"
                :style="v.is_holiday ? 'color:red' : ''"
                >{{ v.day_ad }}
              </span> -->

              <span
                class="v-btn__content english_date"
                :style="
                  v.date_iso == todayNepaliDate
                    ? 'color:#fff'
                    : v.is_holiday
                    ? 'color:red'
                    : ''
                "
                >{{ v.day_ad }}
              </span>

              <span
                @click="personalEventInfo(v.personal_events[0])"
                v-if="v.personal_events.length > 0"
                class="material-icons"
                style="
                  color: yellow;
                  margin-top: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                speaker_notes
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="schoolEvents.length == 0">
        <not-found></not-found>
      </div>
    </div>
    <v-dialog v-model="eventDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title" v-if="!updateFlag">Add</span>
          <span class="title" v-else>Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-3">
          <v-select
            :items="event_type_name"
            class="pa-0"
            label="Select Event Type"
            outlined
            dense
            required
            v-model="event_type_status"
            name="event_type_status"
            :disabled="disable_flag"
          ></v-select>
          <v-form
            ref="form"
            @keydown.native="form.errors.clear($event.target.name)"
            lazy-validation
            v-if="event_type_status === 'School'"
          >
            <v-select
              :items="eventType"
              class="pa-0"
              label="Select Event"
              outlined
              dense
              required
              v-model="form.event_title"
              name="event_title"
              :error-messages="form.errors.get('event_title')"
              :rules="[(v) => !!v || 'Title is required']"
            />

            <v-checkbox style="margin-top: 0" color="blue" v-model="form.range">
              <template v-slot:label>
                <div style="color: #333">
                  Date Range
                  <p style="line-height: 10px">
                    <small style="color: #666; font-size: 10px">
                      Check if event is for multiple days in a row.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
            <v-calendar-field
              :key="count"
              id-val="from_date_calendar"
              v-model="form.event_date"
              required
              name="event_date"
              :label="dateEnable ? 'Event Date' : 'From Date'"
              :error-messages="form.errors.get('event_date')"
              :rules="[(v) => !!v || 'Event is required']"
            >
            </v-calendar-field>
            <br />
            <v-calendar-field
              v-if="!dateEnable"
              id-val="to_date_calendar"
              v-model="form.to_date"
              label="To Date*"
            >
            </v-calendar-field>
            <v-textarea
              outlined
              name="description"
              label="Description (optional)"
              v-model="form.description"
            ></v-textarea>
            <v-checkbox color="#ff5252" v-model="form.is_holiday">
              <template v-slot:label>
                <div style="color: #333">
                  <span style="color: #ff5252">Mark as school holiday</span>
                  <p style="line-height: 10px">
                    <small style="color: #666; font-size: 10px">
                      Check if the above selected event is a school holiday.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
          </v-form>

          <PersonalEvent
            @reset="reset()"
            :reuseComponent="true"
            :event_date="form.event_date"
            :edit_data="edit_data"
            :key="compKey"
            v-else
          ></PersonalEvent>
        </v-card-text>
        <v-card-actions v-if="event_type_status === 'School'">
          <!-- <v-spacer></v-spacer> -->
          <delete-button
            v-if="updateFlag"
            permission="event-delete"
            @agree="deleteEvent()"
          />
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(eventDialog = false), form.reset(), (updateFlag = false)"
            >Close</v-btn
          >
          <v-btn
            color="success"
            text
            v-if="!updateFlag && $auth.can('event-create')"
            @click="save()"
            >Save</v-btn
          >
          <v-btn
            color="success"
            text
            v-if="updateFlag && $auth.can('event-update')"
            @click="updateEvents()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "@/library/Form";
import PersonalEvent from "./personaleventform.vue";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
	components: { PersonalEvent },

	data: () => ({
		compKey: 0,
		event_type_status: "School",
		date: nd,
		headers: [
			{ name: "sunday", nep_day: "आइत ", day: "1" },
			{ name: "monday", nep_day: "सोम", day: "2" },
			{ name: "tuesday", nep_day: "मंगल", day: "3" },
			{ name: "wednesday", nep_day: "बुध", day: "4" },
			{ name: "thursday", nep_day: "बिहि", day: "5" },
			{ name: "friday", nep_day: "शुक्र", day: "6" },
			{ name: "saturday", nep_day: "शनि", day: "7" },
		],
		months: [
			{
				month: "01",
				nep_month: "वैशाख",
				nep_month_eng: "Baishak",
				eng_month: "Apr/May",
			},
			{
				month: "02",
				nep_month: "जेठ",
				nep_month_eng: "Jestha",
				eng_month: "May/Jun",
			},
			{
				month: "03",
				nep_month: "असार",
				nep_month_eng: "Ashad",
				eng_month: "Jun/Jul",
			},
			{
				month: "04",
				nep_month: "साउन",
				nep_month_eng: "Shrwan",
				eng_month: "Jul/Aug",
			},
			{
				month: "05",
				nep_month: "भदौ",
				nep_month_eng: "Bhadra",
				eng_month: "Aug/Sep",
			},
			{
				month: "06",
				nep_month: "असोज",
				nep_month_eng: "Ashoj",
				eng_month: "Sep/Oct",
			},
			{
				month: "07",
				nep_month: "कार्तिक",
				nep_month_eng: "Kartik",
				eng_month: "Oct/Nov",
			},
			{
				month: "08",
				nep_month: "मंसिर",
				nep_month_eng: "Mangsir",
				eng_month: "Nov/Dec",
			},
			{
				month: "09",
				nep_month: "पुष",
				nep_month_eng: "Poush",
				eng_month: "Dec/Jan",
			},
			{
				month: "10",
				nep_month: "माघ",
				nep_month_eng: "Magh",
				eng_month: "Jan/Feb",
			},
			{
				month: "11",
				nep_month: "फागुन",
				nep_month_eng: "Falgun",
				eng_month: "Feb/Mar",
			},
			{
				month: "12",
				nep_month: "चैत",
				nep_month_eng: "Chaitra",
				eng_month: "Mar/Apr",
			},
		],
		dateEnable: true,
		eventDialog: false,
		updateFlag: false,
		disable_flag: false,
		form: new Form(
			{
				event_title: "",
				event_date: "",
				is_holiday: 0,
				range: 0,
				to_date: "",
				day_id: "",
				description: "",
			},
			"/api/events"
		),
		eventId: "",
		schoolEvents: [],
		eventType: [],
		yearEndClassNext: "",
		yearEndClassPrevious: "",
		calendarNepMonth: "",
		calendarNepYear: "",
		calendarEngYear: "",
		calendarEngMonth: "",
		calendarEventWithCount: "",
		count: 1,
		todayNepaliDate: new NepaliDate(new Date()).format("YYYY-MM-DD"),
		event_type_name: ["School", "Personal"],
		edit_data: "",
	}),
	mounted() {
		this.calendarNepMonth = nd.format("MM");
		this.calendarNepYear = nd.format("YYYY");
		this.calendarEngMonth = new Date().getMonth().pad();
		this.calendarEngYear = new Date().getFullYear();
		this.getevent();
		this.getEventType();
	},
	methods: {
		reset() {
			this.eventDialog = false;
			this.event_type_status = "School";
			this.disable_flag = false;
			this.getevent();
		},
		save() {
			if (
				this.form.range == true &&
        (this.form.event_date > this.form.to_date ||
          this.form.event_date == this.form.to_date)
			) {
				return this.$events.fire("notification", {
					message: "Events can only be set on following dates",
					status: "error",
				});
			}
			this.form.fireFetch = false;
			if (this.$refs.form.validate()) {
				this.form.store().then((res) => {
					this.eventDialog = false;
					this.$refs.form.reset();
					this.$localStorage.removeFromStorage("school_event_count");
					this.getevent();
					this.$emit("destroyLocalStorage");
				});
			}
		},

		setData(data, holiday_flag) {
			this.eventId = data.id;
			this.updateFlag = true;
			this.form.event_title = data.event_type.id;
			this.form.is_holiday = holiday_flag;
			this.form.range = data.is_range;
			this.form.day_id = data.calendar_day_id;
			this.form.event_date = data.from_date;
			this.form.to_date = data.to_date;
			this.form.description = data.description;
		},

		updateEvents() {
			this.$rest
				.put("/api/events/" + this.eventId, {
					event_title: this.form.event_title,
					day_id: this.form.day_id,
					event_date: this.form.event_date,
					to_date: this.form.to_date,
					range: this.form.range,
					is_holiday: this.form.is_holiday,
					description: this.form.description,
				})
				.then((res) => {
					if (res.status == 200) {
						this.eventDialog = false;
						this.$refs.form.reset();
						this.$events.fire("notification", {
							message: res.data.message,
							status: "success",
						});
						this.getevent();
					}
				})
				.catch((err) => {});
		},
		deleteEvent() {
			this.$rest
				.delete("/api/events/" + this.eventId)
				.then((res) => {
					if (res.status == 200) {
						this.eventDialog = false;
						this.updateFlag = false;
						this.$localStorage.removeFromStorage("school_event_count");
						this.$events.fire("notification", {
							message: res.data.message,
							status: "success",
						});
						this.getevent();
						this.$emit("destroyLocalStorage");
					}
				})
				.catch((err) => {});
		},
		getevent() {
			this.$rest
				.get(
					"/api/events?month=" +
            this.calendarNepMonth +
            "&&year=" +
            this.calendarNepYear
				)
				.then((res) => {
					this.schoolEvents = res.data;
					this.filterEvents(res.data);
				})
				.catch((err) => {
					this.schoolEvents = [];
				});
		},

		filterEvents(data) {
			const titleData = [];
			data.forEach((events, index) => {
				events.forEach((school_event, s) => {
					if (school_event.events.length > 0) {
						Object.keys(school_event.events).forEach(function (key) {
							titleData.push(
								(school_event.events[key].event_type.name = {
									name: school_event.events[key].event_type.title,
									color_code: school_event.events[key].event_type.color_code,
								})
							);
						});
					}
				});
			});
			let count = {};
			titleData.forEach((element) => {
				count[element.name] = count[element.name]
					? {
						total_count: count[element.name].total_count + 1,
						color: element.color_code,
					}
					: {
						total_count: 1,
						color: element.color_code,
					};
			});
			this.calendarEventWithCount = count;
		},
		getEventType() {
			this.$rest
				.get("/api/event-type")
				.then((res) => {
					this.eventType = res.data.data.map((item) => {
						return { value: item.id, text: item.title };
					});
				})
				.catch((err) => {});
		},
		getEngMonth(month) {
			let monthName = this.engMonths.find((res) => res.month === month);
			return monthName.name;
		},
		getMonthData(month) {
			return this.months.find((res) => res.month === month);
		},
		nextMonth() {
			this.loading = true;
			this.calendarNepMonth = (parseInt(this.calendarNepMonth) + 1).pad();

			if (this.calendarNepMonth === "13") {
				this.calendarNepMonth = "01";
				this.calendarNepYear = parseInt(this.calendarNepYear) + 1;
			}
			this.getevent();
		},
		previousMonth() {
			this.loading = true;

			this.calendarNepMonth = (parseInt(this.calendarNepMonth) - 1).pad();
			if (this.calendarNepMonth === "00") {
				this.calendarNepMonth = "12";
				this.calendarNepYear = parseInt(this.calendarNepYear) - 1;
			}
			this.getevent();
		},
		personalEventInfo(data) {
			this.event_type_status = "Personal";
			this.edit_data = data;
			this.compKey++;
		},
		checkForPersonalInfo(v) {
			if (v.personal_events.length > 0) this.disable_flag = true;
		},
	},
	watch: {
		"form.range": function (v) {
			this.dateEnable = true;
			this.updateFlag !== true ? (this.form.to_date = "") : "";
			if (v) {
				this.dateEnable = false;
				this.updateFlag !== true
					? (this.form.to_date = this.form.event_date)
					: "";
			}
		},
	},
};
</script>
<style>
.v-event-start.v-event-end:hover {
  opacity: 1 !important;
}
.v-text-field.v-text-field--enclosed {
  /* margin-bottom: 12px !important; */
}
.v-event-summary {
  text-align: center;
}
.nepali_date {
  margin-top: 8px;
  font-size: 22px !important;
  font-weight: bold;
}
.english_date {
  font-size: 10px;
  float: right;
  padding: 0 15px 0 0px;
  /* line-height: 80px; */
  font-weight: bold;
  position: absolute;
  top: 105px;
  right: 0;
}

.header_nepali {
  text-align: center;
}
</style>
